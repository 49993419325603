import React, { useEffect, useRef } from "react"
import styled from 'styled-components'
import policy from '../constants/policy';

const number = [1,2,3,4,5,6,7,8,9,10]

const Content = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 80px;
  @media (min-width: 720px){
    width: 680px;
  }
  @media (min-width: 1024px){
    width: 720px;
    padding: 140px 30px 120px;
  }
  h3 {
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    opacity: 0.95;
    margin: 40px 0 15px;
  }
  p {
    font-size: 15px;
    line-height: 26px;
    opacity: 0.8;
    margin-top: 8px;
    margin-bottom: 0;
  }
`;

const policyMapping = (string) => string.split('\n').filter(line => line !== '').map(line => {
  if(number.map(line => `${line}.`).indexOf(line.split(' ')[0]) !== -1){
    return `<h3>${line}</h3>`
  }
  return `<p>${line}</p>`;
}).join('');

const Policy = () => {
  const content = useRef();

  useEffect(() => {
    content.current.innerHTML = policyMapping(policy)
  }, []);

  return <Content>
    <h2 style={{marginBottom: 40}}>개인정보 처리방침</h2>
    <div ref={content}></div>
  </Content>
}

export default Policy